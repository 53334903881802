<template>
  <BaseDialog hideCancel :show-close="false" @confirm="onConfirm">
    <div style="padding-bottom: 40px">
      連結失效，請重新寄送重置信
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'
export default defineComponent({
  name: 'LinkInvalidModal',
  components: {
    BaseDialog,
  },
  setup (props, { emit }) {
    const router = useRouter()
    const onConfirm = () => {
      router.push({ name: 'Login' })
    }
    return { onConfirm }
  },
})
</script>

<style lang="postcss" scoped>

</style>
