<template>
  <div class="">
    <LinkInvalidModal v-if="modal.linkInvalid" />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { VerifyForgetPasswordAuthn } from '@/api/auth'
import { useRoute, useRouter } from 'vue-router/composables'
import { get } from 'lodash'
import store from '@/store'
import LinkInvalidModal from './components/LinkInvalidModal.vue'
import { useSessionStorage } from '@vueuse/core'

export default defineComponent({
  name: 'ResetPassword',
  components: {
    LinkInvalidModal,
  },
  setup (props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const state = useSessionStorage('forgetPasswordState', { code: null, forgetPasswordAuthnId: null })
    const modal = reactive({
      linkInvalid: false,
    })
    const orgId = computed(() => get(store.state, 'org.org.id'))

    onMounted(async () => {
      const forgetPasswordAuthnId = get(route.params, 'forgetPasswordAuthnId')
      const code = get(route.params, 'code')

      const [res, err, rawErr] = await VerifyForgetPasswordAuthn({
        orgId: orgId.value,
        forgetPasswordAuthnId,
        code,
      })
      if (err) {
        if (rawErr.errorCode === 'FORGET_PASSWORD_AUTHN_EXPIRED') {
          modal.linkInvalid = true
        } else {
          window.$message.error(err)
        }
        return
      }

      state.value.code = code
      state.value.forgetPasswordAuthnId = forgetPasswordAuthnId

      router.push({ name: 'SetPassword', query: { guestReset: true } })
    })

    return { modal }
  },
})
</script>

<style lang="postcss" scoped>

</style>
